
import { computed, defineComponent, PropType, ref, useSlots } from "vue";
import BaseIcon from "@/app/core/components/BaseIcon.vue";

export default defineComponent({
  name: "BaseInput",
  components: { BaseIcon },
  emits: ["update:model-value", "blur"],
  props: {
    modelValue: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    errors: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => [],
    },
    maska: {
      type: [String, Object],
      required: false,
    },
    passwordIcon: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    noSelect: {
      type: Boolean,
      required: false,
    },
    min: {
      type: Number,
      required: false,
    },
  },
  setup(props, context) {
    const slots = useSlots();
    const rawValue = ref<string>();

    function onValueChange(value: string | null): void {
      context.emit("update:model-value", value);
    }

    const invalid = computed(() => {
      return props.errors?.length !== 0;
    });

    const isPasswordVisible = ref<boolean>(false);

    return {
      rawValue,
      invalid,
      isPasswordVisible,
      slots,
      onValueChange,
    };
  },
});
