export enum PaymentTerm {
  Immediately = "IMMEDIATELY",
  DAYS_2 = "DAYS_2",
  DAYS_5 = "DAYS_5",
  DAYS_10 = "DAYS_10",
  DAYS_15 = "DAYS_15",
  DAYS_30 = "DAYS_30",
}
export const PaymentTermDisplay = [
  { name: "Immediately", value: PaymentTerm.Immediately },
  { name: "2 business days", value: PaymentTerm.DAYS_2 },
  { name: "5 business days", value: PaymentTerm.DAYS_5 },
  { name: "10 business days", value: PaymentTerm.DAYS_10 },
  { name: "15 business days", value: PaymentTerm.DAYS_15 },
  { name: "30 business days", value: PaymentTerm.DAYS_30 },
];
