<template>
  <div
    class="wrapper"
    :style="[visible ? { visibility: 'visible' } : { visibility: 'hidden' }]"
  >
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpinnerLoader",
  props: {
    visible: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/styles/mixins.scss";
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  @include transparent-background(#d6d9db, 0.6); // Due to no var support
}
.lds-ring {
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  background: var(--white);
  border-radius: 50%;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 45px;
  height: 45px;
  margin: 8px;
  border: 3px solid;
  border-radius: 50%;
  left: 9px;
  top: 9px;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primary) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
