import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-284b7325"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-container" }
const _hoisted_2 = {
  key: 0,
  class: "required"
}
const _hoisted_3 = { class: "input-box" }
const _hoisted_4 = ["placeholder", "value"]
const _hoisted_5 = ["disabled", "min", "type", "placeholder", "value"]
const _hoisted_6 = {
  key: 1,
  class: "errors"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_tooltip = _resolveComponent("base-tooltip")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["label", { invalid: _ctx.invalid }])
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
            : _createCommentVNode("", true),
          (!!_ctx.slots.additional)
            ? (_openBlock(), _createBlock(_component_base_tooltip, { key: 1 }, {
                popper: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "additional", {}, undefined, true)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_base_icon, {
                    class: "info",
                    "icon-src": "static/icons/shared/info.svg",
                    "icon-color": "primary"
                  })
                ]),
                _: 3
              }))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (!!_ctx.slots.prefix)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["prefix", { invalid: _ctx.invalid }])
          }, [
            _renderSlot(_ctx.$slots, "prefix", {}, undefined, true)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.type === 'textarea')
        ? (_openBlock(), _createElementBlock("textarea", {
            key: 1,
            placeholder: _ctx.placeholder,
            class: _normalizeClass({ invalid: _ctx.invalid }),
            value: _ctx.modelValue,
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onValueChange($event.target.value))),
            onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur', $event)))
          }, null, 42, _hoisted_4))
        : _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 2,
            disabled: _ctx.disabled,
            min: _ctx.min,
            style: _normalizeStyle([
          !!_ctx.slots.prefix && !!!_ctx.slots.suffix
            ? { 'border-radius': '0 4px 4px 0', 'border-left': 'none', 'padding-left': '6px' }
            : {},
          !!_ctx.slots.suffix && !!!_ctx.slots.prefix
            ? {
                'border-radius': '4px 0 0 4px',
                'border-right': 'none',
                'padding-right': '5px',
              }
            : {},
          !!_ctx.slots.suffix && !!_ctx.slots.prefix
            ? {
                'border-radius': '0',
                'border-right': 'none',
                'border-left': 'none',
                'padding-left': '5px',
                'padding-right': '5px',
              }
            : {},
          _ctx.passwordIcon ? { 'border-radius': '4px 0 0 4px', 'border-right': 'none' } : {},
        ]),
            class: _normalizeClass([{ invalid: _ctx.invalid }, { 'no-select': _ctx.noSelect }]),
            type: _ctx.isPasswordVisible ? 'text' : _ctx.type,
            placeholder: _ctx.placeholder,
            value: _ctx.modelValue,
            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.maska && _ctx.onValueChange($event.target.dataset.maskRawValue))),
            onInput: _cache[3] || (_cache[3] = ($event: any) => (!_ctx.maska && _ctx.onValueChange($event.target.value))),
            onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('blur', $event)))
          }, null, 46, _hoisted_5)), [
            [_directive_maska, _ctx.maska]
          ]),
      (!!_ctx.slots.suffix)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: _normalizeClass(["suffix", { invalid: _ctx.invalid }])
          }, [
            _renderSlot(_ctx.$slots, "suffix", {}, undefined, true)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.passwordIcon)
        ? (_openBlock(), _createElementBlock("div", {
            key: 4,
            class: _normalizeClass(["password", { invalid: _ctx.invalid }]),
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isPasswordVisible = !_ctx.isPasswordVisible))
          }, [
            _createVNode(_component_base_icon, {
              "icon-color": "additional-medium",
              class: "password-icon",
              "icon-src": 
            _ctx.isPasswordVisible
              ? 'static/icons/shared/eye.svg'
              : 'static/icons/auth/eye-closed.svg'
          
            }, null, 8, ["icon-src"])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    (_ctx.errors.length !== 0)
      ? (_openBlock(), _createElementBlock("section", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, index) => {
            return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(error.$message), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}