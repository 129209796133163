import { SourceType } from "@/app/core/utils/enumDisplayRender";
import { OrderStatus } from "@/app/core/enums/OrderStatus";

export enum DispatchedToMeSort {
  PriceLowestToHighest = "PRICE_ASC",
  PriceHighestToLowest = "PRICE_DESC",
  DeliveredAtEarliestToLatest = "DELIVERED_ASC",
  DeliveredAtLatestToEarliest = "DELIVERED_DESC",
  CanceledAtEarliestToLatest = "CANCELED_ASC",
  CanceledAtLatestToEarliest = "CANCELED_DESC",
  DeliveryDateEarliestToLatest = "DELIVERY_DATE_ASC",
  DeliveryDateLatestToEarliest = "DELIVERY_DATE_DESC",
  PickupDateEarliestToLatest = "PICKUP_DATE_ASC",
  PickupDateLatestToEarliest = "PICKUP_DATE_DESC",
}

export const DispatchedToMeSortDisplay: SourceType[] = [
  {
    name: "Price: Lowest To Highest",
    value: DispatchedToMeSort.PriceLowestToHighest,
    visibleWhenStatusIs: [
      OrderStatus.NotSigned,
      OrderStatus.Dispatched,
      OrderStatus.PickedUp,
      OrderStatus.Delivered,
      OrderStatus.Cancelled,
      OrderStatus.Archived,
      OrderStatus.Issues,
      OrderStatus.WaitingForApproval,
    ],
  },
  {
    name: "Price: Highest To Lowest",
    value: DispatchedToMeSort.PriceHighestToLowest,
    visibleWhenStatusIs: [
      OrderStatus.NotSigned,
      OrderStatus.Dispatched,
      OrderStatus.PickedUp,
      OrderStatus.Delivered,
      OrderStatus.Cancelled,
      OrderStatus.Archived,
      OrderStatus.Issues,
      OrderStatus.WaitingForApproval,
    ],
  },
  {
    name: "Delivered at Date: Earliest first",
    value: DispatchedToMeSort.DeliveredAtEarliestToLatest,
    visibleWhenStatusIs: [OrderStatus.Delivered, OrderStatus.Archived],
  },
  {
    name: "Delivered at Date: Latest first",
    value: DispatchedToMeSort.DeliveredAtLatestToEarliest,
    visibleWhenStatusIs: [OrderStatus.Delivered, OrderStatus.Archived],
  },
  {
    name: "Canceled at Date: Earliest first",
    value: DispatchedToMeSort.CanceledAtEarliestToLatest,
    visibleWhenStatusIs: [OrderStatus.Cancelled],
  },
  {
    name: "Canceled at Date: Latest first",
    value: DispatchedToMeSort.CanceledAtLatestToEarliest,
    visibleWhenStatusIs: [OrderStatus.Cancelled],
  },
  {
    name: "Delivery Date: Earliest first",
    value: DispatchedToMeSort.DeliveryDateEarliestToLatest,
    visibleWhenStatusIs: [OrderStatus.PickedUp],
  },
  {
    name: "Delivery Date: Latest first",
    value: DispatchedToMeSort.DeliveryDateLatestToEarliest,
    visibleWhenStatusIs: [OrderStatus.PickedUp],
  },
  {
    name: "Pickup Date: Earliest first",
    value: DispatchedToMeSort.PickupDateEarliestToLatest,
    visibleWhenStatusIs: [OrderStatus.NotSigned, OrderStatus.Dispatched],
  },
  {
    name: "Pickup Date: Latest first",
    value: DispatchedToMeSort.PickupDateLatestToEarliest,
    visibleWhenStatusIs: [OrderStatus.NotSigned, OrderStatus.Dispatched],
  },
];
