import { GasPrice } from "@/app/core/models/domain/GasPrice";
import { RestService } from "@/app/core/services/core/RestService";

export class GasPriceService extends RestService<GasPrice> {
  constructor() {
    super("/gas-prices", "gas-prices", "");
  }

  async getGasPricesByStates(states?: string[]): Promise<GasPrice[]> {
    return await this.httpGetAll(`${this.apiHref()}`, { params: { states } });
  }
}
