export enum CarrierStatusType {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  NOT_VERIFIED = "NOT_VERIFIED",
}

export const CarrierStatusDisplay = [
  { name: "Active", value: CarrierStatusType.ACTIVE },
  { name: "Inactive", value: CarrierStatusType.INACTIVE },
  { name: "Not verified", value: CarrierStatusType.NOT_VERIFIED },
];
