
import { defineComponent } from "vue";
import BaseIcon from "@/app/core/components/BaseIcon.vue";
import BaseButton from "@/app/core/components/buttons/BaseButton.vue";

export default defineComponent({
  name: "ProfileCard",
  components: { BaseButton, BaseIcon },
  emits: ["edit"],
  props: {
    title: {
      type: String,
      required: false,
    },
    editIcon: {
      type: Boolean,
      required: false,
    },
  },
});
