export enum OrderDateType {
  Estimated = "ESTIMATED",
  Exactly = "EXACTLY",
  NoEarlierThan = "NO_EARLIER_THAN",
  NoLaterThan = "NO_LATER_THAN",
}
export const OrderDateTypeDisplay = [
  { name: "Estimated", value: OrderDateType.Estimated },
  { name: "Exactly", value: OrderDateType.Exactly },
  { name: "No Earlier Than", value: OrderDateType.NoEarlierThan },
  { name: "No Later Than", value: OrderDateType.NoLaterThan },
];
